import { AxiosWrapper } from "~/utils/axios-wrapper";
import {
  createAuthHeader,
  createReqUpdateAuth,
} from "~/utils/http/axios/interceptors";
import type {
  CreateCategoryViaAdminRequest,
  CreateCategoryViaAdminResponse,
} from "~/composables/api/dto/create_category";
import type {
  GetCategoryByIdViaAdminRequest,
  GetCategoryByIdViaAdminResponse,
  GetCategoryRequest,
  GetCategoryResponse,
} from "~/composables/api/dto/get_category_by_id";
import type {
  DeleteCategoryByIdViaAdminRequest,
  DeleteCategoryByIdViaAdminResponse,
} from "~/composables/api/dto/delete_category_by_id";
import type {
  UpdateCategoryViaAdminRequest,
  UpdateCategoryViaAdminResponse,
} from "~/composables/api/dto/update_category";
import type {
  AttachProductToCategoryViaAdminRequest,
  AttachProductToCategoryViaAdminResponse,
} from "~/composables/api/dto/attach_product_to_category";
import type {
  DetachProductFromCategoryViaAdminRequest,
  DetachProductFromCategoryViaAdminResponse,
} from "~/composables/api/dto/detach_product_from_category";
import type {
  GetCategoryProductListViaAdminResponse,
  GetCategoryProductListViaAdminRequest,
  GetCategoryProductListResponse,
  GetCategoryProductListRequest,
} from "~/composables/api/dto/get_category_product_list";
import type {
  GetCategoryListRequest,
  GetCategoryListResponse,
  GetCategoryListViaAdminRequest,
  GetCategoryListViaAdminResponse,
} from "~/composables/api/dto/get_category_list";
import { sanitizeApiData } from "~/utils/object";
import type {
  UpdateCategoryProductSortOrderViaAdminRequest,
  UpdateCategoryProductSortOrderViaAdminResponse,
} from "~/composables/api/dto/update_category_product_sort_order_via_admin";

class CategoryApi extends AxiosWrapper {
  constructor(apiBaseUrl: string) {
    super({
      apiEndpoint: apiBaseUrl,
      config: {
        withCredentials: true,
      },
      interceptors: {
        request: {
          fulfilled: [createAuthHeader],
        },
        response: {
          rejected: [createReqUpdateAuth],
        },
      },
    });
  }

  getCategoryList(data?: GetCategoryListRequest): GetCategoryListResponse {
    return this.get(`${this.apiEndpoint}/api/v1/category/list`, {
      params: {
        page: data?.pagination?.page,
        limit: data?.pagination?.limit,
      },
    });
  }

  getCategoryListViaAdmin(
    data?: GetCategoryListViaAdminRequest,
  ): GetCategoryListViaAdminResponse {
    return this.get(`${this.apiEndpoint}/api/v1/admin/category/list`, {
      params: {
        page: data?.pagination?.page,
        limit: data?.pagination?.limit,

        isActiveCategory: data?.isActiveCategory,
        isDeletedCategory: data?.isDeletedCategory,

        isActiveProduct: data?.isActiveProduct,
        isDeletedProduct: data?.isDeletedProduct,
      },
    });
  }

  /**
   * Создать новую категорию
   * @param data Данные для создания новой категории
   */
  createCategoryViaAdmin(
    data: CreateCategoryViaAdminRequest,
  ): CreateCategoryViaAdminResponse {
    const payload = sanitizeApiData(data);
    return this.post(`${this.apiEndpoint}/api/v1/admin/category`, payload);
  }

  getCategory(data: GetCategoryRequest): GetCategoryResponse {
    return this.get(`${this.apiEndpoint}/api/v1/category/${data.categorySlug}`);
  }

  getCategoryByIdViaAdmin(
    data: GetCategoryByIdViaAdminRequest,
  ): GetCategoryByIdViaAdminResponse {
    return this.get(
      `${this.apiEndpoint}/api/v1/admin/category/${data.categoryId}`,
    );
  }

  deleteCategoryByIdViaAdmin(
    data: DeleteCategoryByIdViaAdminRequest,
  ): DeleteCategoryByIdViaAdminResponse {
    return this.delete(
      `${this.apiEndpoint}/api/v1/admin/category/${data.categoryId}`,
    );
  }

  updateCategoryViaAdmin(
    data: UpdateCategoryViaAdminRequest,
  ): UpdateCategoryViaAdminResponse {
    const payload = sanitizeApiData(data);
    return this.patch(
      `${this.apiEndpoint}/api/v1/admin/category/${payload?.categoryId}`,
      payload,
    );
  }

  attachProductToCategoryViaAdmin(
    data: AttachProductToCategoryViaAdminRequest,
  ): AttachProductToCategoryViaAdminResponse {
    return this.post(
      `${this.apiEndpoint}/api/v1/admin/category/${data.categoryId}/product/${data.productId}`,
      data,
    );
  }

  detachProductFromCategoryViaAdmin(
    data: DetachProductFromCategoryViaAdminRequest,
  ): DetachProductFromCategoryViaAdminResponse {
    return this.delete(
      `${this.apiEndpoint}/api/v1/admin/category/${data.categoryId}/product/${data.productId}`,
    );
  }

  getCategoryProductListViaAdmin(
    data: GetCategoryProductListViaAdminRequest,
  ): GetCategoryProductListViaAdminResponse {
    return this.get(
      `${this.apiEndpoint}/api/v1/admin/category/${data.categoryId}/product/list`,
      {
        params: {
          isActiveProduct: data.isActiveProduct,
          isDeletedProduct: data.isDeletedProduct,
        },
      },
    );
  }

  getCategoryProductList(
    data: GetCategoryProductListRequest,
  ): GetCategoryProductListResponse {
    return this.get(
      `${this.apiEndpoint}/api/v1/category/${data.categorySlug}/product/list`,
      {
        params: {
          isActiveProduct: data.isActiveProduct,
          isDeletedProduct: data.isDeletedProduct,
        },
      },
    );
  }

  updateCategoryProductSortOrderViaAdmin(
    data: UpdateCategoryProductSortOrderViaAdminRequest,
  ): UpdateCategoryProductSortOrderViaAdminResponse {
    const payload = sanitizeApiData(data);

    return this.patch(
      `${this.apiEndpoint}/api/v1/admin/category/${data.categoryId}/product/${data.productId}/sort-order`,
      payload,
    );
  }
}

export const useCategoryApi = createSharedComposable(() => {
  const { NUXT_API_BASE_URL } = useRuntimeConfig().public;

  return new CategoryApi(NUXT_API_BASE_URL);
});
